








































































































































































































































































































































































































































//import axios from 'axios' Watch;
import { Vue, Component } from 'vue-property-decorator';
import { WorkingPeriodResource, MeetingDraft } from '../classes/WorkingPeriod.resource';
import { TaskResource } from '../classes/Task.resource';
import { MeetingStore } from '@/store';
import { MeetingParticipant } from '@/classes/MeetingParticipant.resource';
import { SelectItemOption, ColorPallet } from '@/classes/base/elements';
import { downloadMeetConfigAsJson, getUniqueId, getColorPallets, calculateSwitchValues, getPercentage, createLogObject } from '../helper/index';

@Component({
	components: {
		ColorPicker: () => import('../components/ColorPicker.vue'),
		BaseButton: () => import('../components/base/BaseButton.vue'),
		BaseSelect: () => import('../components/base/BaseSelect.vue'),
		BaseSwitcher: () => import('../components/base/BaseSwitcher.vue'),
		BaseDialog: () => import('../components/base/BaseDialog.vue'),
		BaseToolTip: () => import('../components/base/BaseToolTip.vue')
	},
	beforeRouteLeave(to, from, next) {
		const self = this as any;
		self.exitUrl = to.path;

		if (self.exitConfirmed || self.emptyForm) {
			next();
		} else {
			self.exitDialog = true;
		}
	}
})
export default class CreateMeetingForm extends Vue {
	//Meeting Details
	public workingPeriodTemplateObject: WorkingPeriodResource = null as unknown as WorkingPeriodResource;
	public templateId = 0; //Id of meet if its a template (func copyTemplate)
	public title = '';
	public place = '';
	public timePick = '';
	public datePick = new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10);
	public meetPauseInput = 0;
	// userInput - if autoCount enabled, save the input of user before replace with autoPrediction and paste back if turned off
	public userMeetPauseInput = 0;
	public meetBufferInput = 0;
	// userInput - if autoCount enabled, save the input of user before replace with autoPrediction and paste back if turned off
	public userMeetBufferInput = 0;
	public meetDiscussionTime = 0;
	public templateMode = false;
	errors = false;
	autoBufferPauseInput = true;

	//Task
	public agendaListHovering = false;
	public taskId = 0; //Task Id - used by createTask
	public taskName = 'Topic ' + (this.taskId + 1);
	public taskGoal = '';
	public taskTimeNeeded = 10;
	public Tasks: TaskResource[] = [];
	public taskEditMode = false;
	public taskBeforeEdit: TaskResource = null as unknown as TaskResource;
	public editedTaskKey = 0; //Task Id used by update/edit Task
	public taskToDelete: TaskResource = null as unknown as TaskResource;
	public confirmTaskDelete = false;
	public options: SelectItemOption[] = [
		{
			name: 'edit',
			description: 'Edit',
			icon: {
				name: 'pencil'
			}
		},
		{
			name: 'move-up',
			description: 'Move Up',
			icon: {
				name: 'chevron-up-circle-outline'
			}
		},
		{
			name: 'move-down',
			description: 'Move Down',
			icon: {
				name: 'chevron-down-circle-outline'
			}
		},
		{
			name: 'delete',
			description: 'Delete',
			icon: {
				name: 'close'
			}
		}
	];
	public pallets: ColorPallet[] = [];
	public activePalletId = 1;
	public changedTasksOrder = false;
	taskGoalValidationErrors = false;

	//People
	public nextParticipantId = 0;
	public participantName = '';
	public isNavigator = false;
	public participants: MeetingParticipant[] = [];

	//Other
	public errorMessages = false;
	public workingPeriodsNames: string[] = []; // array with meet names from localStore
	public openedPanel = 0;
	public originalTitle = '';
	public wasFinished = false; // Flag if template meet was finished
	public showSaveWarningDialog = false;
	public hideSaveWarningDialog = false;
	public pageEnteredAt = 0;
	public pageLeftAt = 0;
	public exitDialog = false;
	public exitConfirmed = false;
	public exitUrl = '';
	public pausedDialog = false;

	/* Example of Watch implementation
	@Watch('meetPauseInput', { immediate: true, deep: true })
	onMeetPauseInputChange(newVal: number, oldVal: number) {
		// Handle the change in the meetBufferInput property
		console.log('meetPauseInput changed from', oldVal, 'to', newVal);
		this.var = true;
		// You can perform additional actions here
	}

	@Watch('meetBufferInput', { immediate: true, deep: true })
	onMeetBufferInputChange(newVal: number, oldVal: number) {
		// Handle the change in the meetBufferInput property
		console.log('meetBufferInput changed from', oldVal, 'to', newVal);
		this.var = true;
		// You can perform additional actions here
	}*/

	async mounted(): Promise<void> {
		if (localStorage.getItem('MeetingTimer-Notification')) {
			const tmpShowWarning = localStorage.getItem('MeetingTimer-Notification');
			if (tmpShowWarning) {
				this.hideSaveWarningDialog = Boolean(JSON.parse(tmpShowWarning));
			}
		}

		this.timePick = this.getPredictedStartTime();
		if (this.$route.params.templateId) {
			this.templateId = Number(this.$route.params.templateId);
			if (String(this.$route.path).includes('template')) {
				this.templateMode = true;
			}
		}
		if (localStorage.getItem('MeetingTimer-Draft') && this.$route.path === '/create') {
			this.pausedDialog = true;
		}
		if (MeetingStore.workingPeriods.length > 0) {
			MeetingStore.workingPeriods.forEach((meet) => {
				this.workingPeriodsNames.push(meet.title);
			});
		}

		if (MeetingStore.workingPeriods.length > 0 && this.templateId > 0) {
			const target = MeetingStore.workingPeriods.find((meet) => Number(meet.id) === this.templateId);
			if (target) {
				this.workingPeriodTemplateObject = JSON.parse(JSON.stringify(target)); // Object.assign({}, target);
				if (this.workingPeriodTemplateObject.status === 'finished') {
					this.wasFinished = true;
					this.workingPeriodTemplateObject.tasks.forEach((task) => ((task.secondsNeeded = task.startSecondsNeeded), (task.secondsLeft = task.secondsNeeded), (task.assignments = []), (task.secondsCounted = 0), (task.isRunning = false), (task.status = 'not-started')));
				}
				this.workingPeriodTemplateObject.title ? (this.title = this.workingPeriodTemplateObject.title) : '';
				this.taskId = this.workingPeriodTemplateObject.tasks.length;
				this.workingPeriodTemplateObject.place ? (this.place = this.workingPeriodTemplateObject.place) : '';
				this.workingPeriodTemplateObject.meetDay ? (this.templateMode ? (this.datePick = '') : (this.datePick = new Date(this.workingPeriodTemplateObject.meetDay).toISOString().substring(0, 10))) : '';
				this.workingPeriodTemplateObject.meetTime ? (this.templateMode ? (this.timePick = this.getPredictedStartTime()) : (this.timePick = this.workingPeriodTemplateObject.meetTime)) : '';
				this.workingPeriodTemplateObject.tasks ? (this.Tasks = this.workingPeriodTemplateObject.tasks) : [];
				this.Tasks.forEach((task: TaskResource) => {
					task.overtimeSecondsCounted = 0;
				});
				this.workingPeriodTemplateObject.participants ? (this.participants = this.workingPeriodTemplateObject.participants) : [];
				this.workingPeriodTemplateObject.pause ? (this.meetPauseInput = this.workingPeriodTemplateObject.pause.secondsNeeded / 60) : 0;
				this.workingPeriodTemplateObject.buffer ? (this.meetBufferInput = this.workingPeriodTemplateObject.buffer.secondsNeeded / 60) : 0;
				this.nextParticipantId = this.workingPeriodTemplateObject.participants.length;
			} else {
				this.$router.push(`/create`);
			}
		}
		this.updateDiscussionTime();
		this.originalTitle = this.title;
		this.taskName = 'Topic ' + (this.taskId + 1);
		this.pallets = getColorPallets();
		this.pageEnteredAt = Date.now();
		window.addEventListener('beforeunload', () => {
			//this.closePage();
		});
	}

	async beforeUnmount(): Promise<void> {
		window.removeEventListener('beforeunload', () => {
			//this.closePage();
		});
	}

	async leaveWithDraft(): Promise<void> {
		this.exitConfirmed = true;
		const draftMeeting: MeetingDraft = {
			title: this.title,
			place: this.place,
			datePick: new Date(this.datePick),
			timePick: this.timePick,
			Tasks: this.Tasks,
			participants: this.participants,
			meetPauseInput: this.meetPauseInput,
			meetBufferInput: this.meetBufferInput
		};
		localStorage.setItem('MeetingTimer-Draft', JSON.stringify(draftMeeting));
		this.$router.push(this.exitUrl);
	}

	async leaveWithoutDraft(): Promise<void> {
		this.exitConfirmed = true;
		this.$router.push(this.exitUrl);
	}

	async discardDraft(): Promise<void> {
		localStorage.removeItem('MeetingTimer-Draft');
		this.pausedDialog = false;
	}

	async continueDraft(): Promise<void> {
		const draft: string | null = localStorage.getItem('MeetingTimer-Draft');
		//let nextId = 0;
		if (draft) {
			const meetingDraft = JSON.parse(draft);
			if (meetingDraft) {
				this.title = meetingDraft.title ? meetingDraft.title : '';
				this.place = meetingDraft.place ? meetingDraft.place : '';
				this.datePick = meetingDraft.datePick ? new Date(meetingDraft.datePick).toISOString().substr(0, 10) : new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10);
				this.timePick = meetingDraft.timePick ? meetingDraft.timePick : '';
				this.Tasks = meetingDraft.Tasks ? meetingDraft.Tasks : [];
				this.participants = meetingDraft.participants ? meetingDraft.participants : [];
				this.meetPauseInput = meetingDraft.meetPauseInput ? meetingDraft.meetPauseInput : 0;
				this.meetBufferInput = meetingDraft.meetBufferInput ? meetingDraft.meetBufferInput : 0;

				this.Tasks.forEach((task: TaskResource) => {
					task.id = this.taskId;
					this.taskId++;
				});
				this.taskName = 'Topic ' + (this.taskId + 1);
			}

			localStorage.removeItem('MeetingTimer-Draft');
		}
		this.pausedDialog = false;
	}

	//Participants

	async addParticipant(): Promise<void> {
		if (this.participantName) {
			const participant: MeetingParticipant = {
				id: this.nextParticipantId,
				roles: this.isNavigator ? ['Meeting Navigator'] : ['Participant'],
				name: this.participantName
			};
			this.participants.push(participant);
			this.isNavigator = false;
			this.nextParticipantId++;
			this.participantName = '';
		}
	}

	async deleteParticipant(participant: MeetingParticipant): Promise<void> {
		const index = this.participants.indexOf(participant, 0);
		this.participants.splice(index, 1);
	}

	//Tasks

	get activeColor(): string {
		return this.activePallet.colors[2 % this.activePallet.colors.length];
	}

	async validateTaskInput(): Promise<void> {
		//form validation
		if (this.taskName !== '' && this.taskGoal !== '' && this.taskTimeNeeded > 0 && this.taskTimeNeeded <= 999 && String(this.taskTimeNeeded).length > 0) {
			if (this.taskEditMode) {
				this.updateTask();
			} else {
				this.createTask();
			}
			this.updateDiscussionTime();
			if (this.taskGoalValidationErrors) this.taskGoalValidationErrors = false;
			if (this.autoBufferPauseInput) {
				this.updatePausePrediction();
				this.updateBufferPrediction();
			}
		} else {
			this.errorMessages = true;
			if (this.taskGoal.length === 0) {
				this.taskGoalValidationErrors = true;
			}
		}
	}

	async createTask(): Promise<void> {
		const taskSeconds = Number(parseFloat(String(this.taskTimeNeeded)).toFixed(0)) * 60;
		if (this.taskName != '') {
			const task: TaskResource = {
				id: this.taskId,
				title: this.taskName, //title
				startSecondsNeeded: taskSeconds, //startSecondsNeeded
				secondsNeeded: taskSeconds, //secondsNeeded
				secondsLeft: taskSeconds, //secondsLeft
				secondsCounted: 0, //secondsCounted
				overtimeSecondsCounted: 0, //secondsCounted
				duration: Number(parseFloat(String(this.taskTimeNeeded)).toFixed(0)), //duration
				isRunning: false, //isRunning
				goal: this.taskGoal, //goal
				assignments: [],
				status: 'not-started',
				start: TaskResource.prototype.start,
				stop: TaskResource.prototype.stop
			};
			this.Tasks.push(task);
			//this.updatePausePrediction();
			//this.updateBufferPrediction();
			this.taskId++;
			this.taskName = 'Topic ' + (this.taskId + 1);
			this.taskGoal = '';
			this.taskTimeNeeded = 10;
			this.errorMessages = false;
		}
	}

	async editTask(task: TaskResource, key: number): Promise<void> {
		this.taskBeforeEdit = task;
		this.editedTaskKey = key;
		this.taskName = task.title;
		this.taskTimeNeeded = Number(task.startSecondsNeeded / 60);
		this.taskGoal = task.goal;
	}

	async moveTaskBack(task: TaskResource): Promise<void> {
		const index = this.Tasks.indexOf(task, 0);
		if (index >= 1) {
			const prevIndex = index - 1;
			const element = this.Tasks[index];
			this.Tasks.splice(index, 1);
			this.Tasks.splice(prevIndex, 0, element);
		}
		this.changedTasksOrder = true;
	}

	async moveTaskForward(task: TaskResource): Promise<void> {
		const index = this.Tasks.indexOf(task, 0);
		if (index < this.Tasks.length - 1) {
			const nextIndex = index + 1;
			const element = this.Tasks[index];
			this.Tasks.splice(index, 1);
			this.Tasks.splice(nextIndex, 0, element);
		}
		this.changedTasksOrder = true;
	}

	async updateTask(): Promise<void> {
		const taskSeconds = Number(parseFloat(String(this.taskTimeNeeded)).toFixed(0)) * 60;
		const task: TaskResource = {
			id: this.taskBeforeEdit.id,
			title: this.taskName, //title
			startSecondsNeeded: taskSeconds, //startSecondsNeeded
			secondsNeeded: taskSeconds, //secondsNeeded
			secondsLeft: taskSeconds, //secondsLeft
			secondsCounted: 0, //secondsCounted
			overtimeSecondsCounted: 0, //secondsCounted
			duration: Number(parseFloat(String(this.taskTimeNeeded)).toFixed(0)), //duration
			isRunning: false, //isRunning
			goal: this.taskGoal, //color
			assignments: [],
			status: 'not-started',
			start: TaskResource.prototype.start,
			stop: TaskResource.prototype.stop
		};
		const target = this.Tasks.find((task) => Number(task.id) === this.taskBeforeEdit.id);
		if (target) {
			Object.assign(target, task);
		}
		this.taskEditMode = false;
		this.taskBeforeEdit = null as unknown as TaskResource;
		this.editedTaskKey = 0;
		this.taskName = 'Topic ' + (this.taskId + 1);
		this.taskGoal = '';
		this.taskTimeNeeded = 10;
		this.errorMessages = false;
	}

	async deleteTask(): Promise<void> {
		if (this.taskEditMode && this.taskToDelete.id === this.taskBeforeEdit.id) {
			this.taskName = 'Topic ' + (this.taskId + 1);
			this.taskGoal = '';
			this.taskTimeNeeded = 10;
			this.taskEditMode = false;
		}
		const index = this.Tasks.indexOf(this.taskToDelete, 0);
		this.Tasks.splice(index, 1);
		this.taskToDelete = null as unknown as TaskResource;
	}

	async setPallet(palletId: number): Promise<void> {
		this.activePalletId = palletId;
	}

	//Meet

	async closeSaveWarning(): Promise<void> {
		if (this.hideSaveWarningDialog) {
			const obj: string = JSON.stringify(this.hideSaveWarningDialog);
			localStorage.setItem('MeetingTimer-Notification', obj);
		}
		this.showSaveWarningDialog = false;
		this.$router.push('/');
	}

	async downloadObjectAsJson(): Promise<void> {
		downloadMeetConfigAsJson(MeetingStore.workingPeriods, MeetingStore.globalId, MeetingStore.clientId);
		this.closeSaveWarning();
	}

	getPredictedStartTime(): string {
		const today = new Date();
		const h = today.getHours();
		const newHour = h + 2;
		if (newHour < 24) {
			return newHour + ':00';
		} else {
			return (newHour % 24) + ':00';
		}
	}

	getDuration(secondsNeeded: number): string {
		const seconds = secondsNeeded;
		const minutes = Math.floor(seconds / 60);
		const secondsLeft = seconds % 60;
		if (minutes == 0 && secondsLeft == 0) {
			return '00:00';
		}
		return (minutes < 10 ? '0' : '') + minutes + ':' + (secondsLeft < 10 ? '0' : '') + secondsLeft;
	}

	async createMeet(): Promise<void> {
		const breakpoints = calculateSwitchValues(this.Tasks);
		const percentage = getPercentage(this.Tasks);
		let secondsNeeded = 0;
		this.Tasks.forEach((task) => ((secondsNeeded += task.secondsNeeded), (task.color = this.activePallet.colors[task.id % this.activePallet.colors.length]))); // calculating total seconds
		const pauseDuration = this.getDuration(this.meetPauseSeconds);
		const bufferDuration = this.getDuration(this.meetBufferSeconds);
		const secondsCounted = 0;
		const createdAt = new Date();
		const activeTask = 0;

		const res: WorkingPeriodResource = {
			id: Number(MeetingStore.globalId),
			uuid: getUniqueId(20),
			title: this.title, //title
			status: 'not-started', //title - this.templateMode ? 'template' : 'not-started'
			place: this.place, //ort
			meetDay: new Date(this.datePick),
			meetTime: this.timePick,
			secondsNeeded: secondsNeeded, // secondsNeeded total (secondsLeft assigned to secondsNeeded in constructor)
			secondsLeft: secondsNeeded,
			secondsCounted: secondsCounted,
			overtimeSecondsCounted: 0,
			timeBeforeJump: 0,
			isRunning: false, //isRunning
			createdAt: createdAt, //createdAt
			tasks: this.Tasks, //tasks
			activeTask: activeTask, // activeTask. Default 0.
			breakpoints: breakpoints, //breakpoints
			percentage: percentage, //percentage
			initialPercentage: percentage,
			duration: this.entireTime, // + ':00',
			possibleEndTime: this.possibleEndTime,
			participants: this.participants,
			updateCount: 0,
			orderedListCount: 0,
			pause: {
				duration: Number(pauseDuration.split(':')[0]),
				secondsNeeded: this.meetPauseSeconds,
				secondsLeft: this.meetPauseSeconds,
				secondsCounted: 0
			},
			buffer: {
				duration: Number(bufferDuration.split(':')[0]),
				secondsNeeded: this.meetBufferSeconds,
				secondsLeft: this.meetBufferSeconds,
				secondsCounted: 0
			},
			start: WorkingPeriodResource.prototype.start,
			stop: WorkingPeriodResource.prototype.stop
		};
		MeetingStore.addWorkingPeriod(res); //add meet to vuex
		MeetingStore.incr(); //increment global id
		this.templateMode = false;
		this.exitConfirmed = true;
		this.pageLeftAt = Date.now();
		const meetingEditTime = (this.pageLeftAt - this.pageEnteredAt) / 1000;

		createLogObject(res, 'Create', this.changedTasksOrder, meetingEditTime);
		//let meetingLog = createLogObject(meet, 'Create', this.changedTasksOrder, meetingEditTime);

		/*await axios
			.post(MeetingStore.apiUri, meetingLog)
			.then(function (response) {
				console.log(response);
			})
			.catch(function (error) {
				console.log(error);
			});
		*/
		if (!this.hideSaveWarningDialog) {
			this.showSaveWarningDialog = true;
		} else {
			this.$router.push('/');
		}
	}

	async updateMeet(): Promise<void> {
		const breakpoints = calculateSwitchValues(this.Tasks);
		const percentage = getPercentage(this.Tasks);
		let secondsNeeded = 0;
		let newTaskId = 0;
		this.Tasks.forEach((task) => (((task.id = newTaskId), newTaskId++), (secondsNeeded += task.secondsNeeded), (task.color = this.activePallet.colors[task.id % this.activePallet.colors.length])));

		// calculating total seconds
		//const duration = this.getDuration(secondsNeeded);
		const pauseDuration = this.getDuration(this.meetPauseSeconds);
		const bufferDuration = this.getDuration(this.meetBufferSeconds);
		const secondsCounted = 0;
		const createdAt = new Date();
		const activeTask = 0;
		if (this.title != '') {
			const res: WorkingPeriodResource = {
				id: this.templateId,
				uuid: this.workingPeriodTemplateObject.uuid,
				title: this.title, //title
				status: this.workingPeriodTemplateObject.status ? this.workingPeriodTemplateObject.status : 'not-started', //status template
				place: this.place, //ort
				meetDay: new Date(this.datePick),
				meetTime: this.timePick,
				secondsNeeded: secondsNeeded, // secondsNeeded total (secondsLeft assigned to secondsNeeded in constructor)
				secondsLeft: secondsNeeded,
				secondsCounted: secondsCounted,
				overtimeSecondsCounted: 0,
				timeBeforeJump: 0,
				isRunning: false, //isRunning
				createdAt: createdAt, //createdAt
				tasks: this.Tasks, //tasks
				activeTask: activeTask, // activeTask. Default 0.
				breakpoints: breakpoints, //breakpoints
				percentage: percentage, //percentage
				initialPercentage: percentage,
				duration: this.entireTime, //+ ':00',
				possibleEndTime: this.possibleEndTime,
				participants: this.participants,
				updateCount: this.workingPeriodTemplateObject.updateCount + 1,
				orderedListCount: 0,
				pause: {
					duration: Number(pauseDuration.split(':')[0]),
					secondsNeeded: this.meetPauseSeconds,
					secondsLeft: this.meetPauseSeconds,
					secondsCounted: 0
				},
				buffer: {
					duration: Number(bufferDuration.split(':')[0]),
					secondsNeeded: this.meetBufferSeconds,
					secondsLeft: this.meetBufferSeconds,
					secondsCounted: 0
				},
				start: WorkingPeriodResource.prototype.start,
				stop: WorkingPeriodResource.prototype.stop
			};
			MeetingStore.updateWorkingPeriod(res);
			this.pageLeftAt = Date.now();
			const meetingEditTime = (this.pageLeftAt - this.pageEnteredAt) / 1000;

			createLogObject(res, 'Update', this.changedTasksOrder, meetingEditTime);

			/*await axios
				.post(MeetingStore.apiUri, meetingLog)
				.then(function (response) {
					console.log(response);
				})
				.catch(function (error) {
					console.log(error);
				});

			await fetch(MeetingStore.apiUri, {
				method: 'POST',
				body: JSON.stringify(meetingLog),
				headers: { 'Content Type': 'application/json' }
			});*/

			this.templateMode = false;
			this.exitConfirmed = true;
			this.$router.push('/');
		}
	}

	async updateBufferPrediction(): Promise<void> {
		let discussionTime = 0;
		if (this.Tasks.length > 0) {
			this.Tasks.forEach((task) => (discussionTime = discussionTime + task.startSecondsNeeded));
		}
		if (discussionTime > 0) {
			let buffer = 0;
			if (discussionTime <= 1500) {
				buffer = 5;
			} else if (discussionTime > 1500 && discussionTime <= 7200) {
				buffer = 10;
			} else if (discussionTime > 7200) {
				buffer = 20;
			}
			this.meetBufferInput = buffer;
		}
	}

	async updatePausePrediction(): Promise<void> {
		let discussionTime = 0;
		if (this.Tasks.length > 0) {
			this.Tasks.forEach((task) => (discussionTime = discussionTime + task.startSecondsNeeded));
		}
		if (discussionTime > 0) {
			let pause = 0;
			if (discussionTime <= 3600) {
				pause = 0;
			} else if (discussionTime > 3600 && discussionTime <= 6900) {
				pause = 5;
			} else if (discussionTime > 6900 && discussionTime <= 10800) {
				pause = 10;
			} else if (discussionTime > 10800 && discussionTime <= 14400) {
				pause = 20;
			} else if (discussionTime > 14400 && discussionTime <= 21600) {
				pause = 30;
			} else if (discussionTime > 21600) {
				pause = 60;
			}
			this.meetPauseInput = pause;
		}
	}

	async updateDiscussionTime(): Promise<void> {
		let discussionTime = 0;
		this.Tasks.forEach((task) => (discussionTime = discussionTime + task.startSecondsNeeded));
		const minutes = Math.floor(discussionTime / 60);
		this.meetDiscussionTime = minutes;
	}
	/** used when switch color palets */
	async rerenderColors(): Promise<void> {
		this.Tasks.forEach((task) => {
			task.color = this.activePallet.colors[task.id % this.activePallet.colors.length];
		});
	}

	async showErors(): Promise<void> {
		this.errors = true;
	}

	async handleToggle() {
		if (this.autoBufferPauseInput === false) {
			this.autoBufferPauseInput = true;
			this.userMeetPauseInput = this.meetPauseInput;
			this.userMeetBufferInput = this.meetBufferInput;
			this.updatePausePrediction();
			this.updateBufferPrediction();
		} else {
			this.autoBufferPauseInput = false;
			this.meetPauseInput = this.userMeetPauseInput;
			this.meetBufferInput = this.userMeetBufferInput;
		}
	}

	//Getters

	get activePallet(): ColorPallet {
		if (this.activePalletId > 0) {
			const target = this.pallets.find((pallet) => Number(pallet.id) === this.activePalletId);
			if (target) return target;
			else {
				const empty: ColorPallet = {
					id: 0,
					colors: ['#B3B3B3', '#808080']
				};
				return empty;
			}
		} else {
			const empty: ColorPallet = {
				id: 0,
				colors: ['#B3B3B3', '#808080']
			};
			return empty;
		}
	}

	get averageStatus(): boolean {
		if (this.inputP1Status && this.inputP2Status && this.inputP3Status && this.inputP4Status) {
			return true;
		} else {
			return false;
		}
	}

	get allParticipants(): MeetingParticipant[] {
		const people: MeetingParticipant[] = [];
		if (this.participants.length !== 0) {
			this.participants.forEach((person) => people.push(person));
		}
		return people;
	}

	get emptyForm(): boolean {
		if (this.title.length === 0 && this.place.length === 0 && this.participants.length === 0 && this.Tasks.length === 0 && this.meetPauseInput === 0 && this.meetBufferInput === 0) return true;
		else return false;
	}

	get meetPauseSeconds(): number {
		if (Number(this.meetPauseInput) > 0) {
			const seconds = Number(parseFloat(String(this.meetPauseInput)).toFixed(0)) * 60;
			return seconds;
		} else {
			return 0;
		}
	}

	get meetBufferSeconds(): number {
		if (Number(this.meetBufferInput) > 0) {
			const seconds = Number(parseFloat(String(this.meetBufferInput)).toFixed(0)) * 60;
			return seconds;
		} else {
			return 0;
		}
	}

	get inputP1Status(): boolean {
		if (this.title.length > 0 && this.place.length > 0 && this.timePick && this.datePick && this.validMeetDate) {
			return true;
		} else {
			return false;
		}
	}

	get inputP2Status(): boolean {
		if (this.participants.length !== 0) {
			return true;
		} else {
			return false;
		}
	}

	get inputP3Status(): boolean {
		if (this.Tasks.length > 0) {
			return true;
		} else {
			return false;
		}
	}

	get inputP4Status(): boolean {
		if (Number(this.meetPauseInput) > -1 && Number(this.meetPauseInput) < 1000 && Number(this.meetBufferInput) > -1 && Number(this.meetBufferInput) < 1000) {
			return true;
		} else {
			return false;
		}
	}

	get meetingNavigators(): MeetingParticipant[] {
		const meetingNavigators: MeetingParticipant[] = [];
		this.participants.forEach((participant) => {
			if (participant.roles.indexOf('Meeting Navigator') > -1) {
				meetingNavigators.push(participant);
			}
		});
		return meetingNavigators;
	}

	get meetParticipants(): MeetingParticipant[] {
		const meetParticipants: MeetingParticipant[] = [];
		this.participants.forEach((participant) => {
			if (participant.roles.indexOf('Participant') > -1) {
				meetParticipants.push(participant);
			}
		});
		return meetParticipants;
	}

	get meetParticipantExist(): boolean {
		let target = null;
		this.participants.forEach((participant) => {
			if (this.participantName.length > 0 && participant.name === this.participantName) {
				target = participant;
			}
		});
		if (target) return true;
		else return false;
	}

	get validMeetDate(): boolean {
		if (this.datePick) {
			const now: Date = new Date();
			let today = '';
			const dd = String(now.getDate()).padStart(2, '0');
			const mm = String(now.getMonth() + 1).padStart(2, '0'); //January is 0!
			const yyyy = now.getFullYear();

			today = yyyy + '-' + mm + '-' + dd;
			return String(this.datePick) >= today ? true : false;
		} else {
			return false;
		}
	}
	//entire time in minutes with pause & buffer
	get entireTime(): number {
		let entireTime = 0;
		let discussionTime = 0;
		this.Tasks.forEach((task) => (discussionTime = discussionTime + task.startSecondsNeeded));
		if (discussionTime > 0) {
			entireTime = entireTime + discussionTime;
		}
		if (this.meetPauseSeconds > 0) {
			entireTime = entireTime + this.meetPauseSeconds;
		}
		if (this.meetBufferSeconds > 0) {
			entireTime = entireTime + this.meetBufferSeconds;
		}
		const minutes = Math.floor(entireTime / 60);
		return minutes;
	}

	get possibleEndTime(): string {
		let possibleEndTime = 0;
		if (this.timePick.length > 0) {
			const taskTime = this.timePick.split(':');
			const timeAsMinutes = +taskTime[0] * 60 + +taskTime[1];
			possibleEndTime = timeAsMinutes + Number(this.entireTime);
			const hours = Math.floor(possibleEndTime / 60);
			const minutes = possibleEndTime % 60;
			if (hours == 0 && minutes == 0) {
				return '00:00';
			}
			if (hours > 23) {
				const newHours = (hours % 23) - 1;
				return (newHours < 10 ? '0' : '') + newHours + ':' + (minutes < 10 ? '0' : '') + minutes;
			}
			return (hours < 10 ? '0' : '') + hours + ':' + (minutes < 10 ? '0' : '') + minutes;
		} else {
			return '';
		}
	}

	get predictedHourStart(): string {
		const date = new Date();
		const dateStr = ('00' + date.getHours()).slice(-2) + ':' + ('00' + date.getMinutes()).slice(-2);
		return dateStr;
	}
}
