import moment from 'moment';
import { MeetingParticipant } from './MeetingParticipant.resource';
import { TaskResource } from './Task.resource';

export type WorkingPeriodStatus = 'template' | 'not-started' | 'running' | 'paused' | 'finished' | 'overtime';

export class WorkingPeriodResource {
	id: number;
	uuid: string;
	title: string;
	status: WorkingPeriodStatus;
	place: string;
	meetDay: Date;
	meetTime: string;
	secondsNeeded: number;
	secondsLeft: number;
	secondsCounted: number;
	overtimeSecondsCounted: number;
	timeBeforeJump: number;
	duration: number;
	possibleEndTime: string;
	isRunning: boolean;
	createdAt: Date;
	due?: Date;
	interval?: any;
	tasks: TaskResource[];
	activeTask: number;
	breakpoints: number[];
	percentage: number[];
	initialPercentage: number[];
	participants: MeetingParticipant[];
	updateCount: number;
	orderedListCount: number;
	pause: {
		duration: number;
		secondsNeeded: number;
		secondsLeft: number;
		secondsCounted: number;
	};
	buffer: {
		duration: number;
		secondsNeeded: number;
		secondsLeft: number;
		secondsCounted: number;
	};

	constructor(id: number, uuid: string, title: string, status: WorkingPeriodStatus, place: string, meetDay: Date, meetTime: string, secondsNeeded: number, secondsCounted: number, overtimeSecondsCounted: number, isRunning: boolean, createdAt: Date, tasks: TaskResource[], activeTask: number, breakpoints: number[], percentage: number[], initialPercentage: number[], duration: number, possibleEndTime: string, participants: MeetingParticipant[], updateCount: number, orderedListCount: number) {
		this.id = id;
		this.uuid = uuid;
		this.title = title;
		this.status = status;
		this.place = place;
		this.meetDay = meetDay;
		this.meetTime = meetTime;
		this.secondsNeeded = secondsNeeded;
		this.secondsLeft = secondsNeeded;
		this.secondsCounted = secondsCounted;
		this.overtimeSecondsCounted = overtimeSecondsCounted;
		this.isRunning = isRunning;
		this.createdAt = createdAt;
		this.tasks = tasks;
		this.activeTask = activeTask;
		this.breakpoints = breakpoints;
		this.percentage = percentage;
		this.initialPercentage = initialPercentage;
		this.duration = duration;
		this.possibleEndTime = possibleEndTime;
		this.participants = participants;
		this.updateCount = updateCount;
		this.orderedListCount = orderedListCount;
	}

	start(): void {
		this.due = moment() // Timestamp now
			.add(this.secondsNeeded, 'seconds') // add seconds needed to timestamp
			.toDate();
	}

	stop(): void {
		this.due = moment().toDate();
	}
}

export interface MeetingConfig {
	workingPeriods: WorkingPeriodResource[];
	globalID: number; //GlobalMeetingId
	clientId: string;
}

export interface MeetingLog {
	// default
	version: string; // number as string 1.0;
	clientId: string;
	uuid: string;
	event: string;
	timestamp: number;
	upcomingMeetings: number;
	totalFinishedMeetings: number;
	titleLength: number;
	placeLength: number;
	meetingDate: string; // 01.01.2001
	meetingDay: string; //Monday
	meetingTime: string;
	participantsCount: number;
	averageSpacePerParticipantName: number[];
	placeIsUrl: boolean;
	totalMeetingDuration: number; // in min
	totalTasksCount: number;
	tasksTitleLength: number[];
	tasksGoalLength: number[];
	totalTasksDuration: number[]; // in min
	pauseDuration: number;
	bufferDuration: number;
	userAgent: string;
	updateCount?: number;
	changedTasksOrder?: boolean;
	meetingGapFromToday?: number;
	gapFromPlannedStart?: number; // in sec
	meetingActualDuration?: number; // in sec
	actualMeetingTasksDuration?: number[]; // in sec
	taskNotesCount?: number[];
	todosWithoutDeadline?: number;
	todosWithDeadline?: number;
	nodeadlineTodosResponsibleCount?: number[];
	deadlineTodosResponsibleCount?: number[];
	externalParticipantsCount?: number;
	meetingEditTime?: number;
	// not implemented
	// meetingProvider: string; // Zoom etc.
}

export interface CreateMeetingLog extends MeetingLog {
	meetingGapFromToday: number;
}

export interface FinishMeetingLog extends MeetingLog {
	meetingActualDuration: number; // in sec
	actualMeetingTasksDuration: number[]; // in sec
	taskNotesCount: number[];
	todosWithoutDeadline: number;
	todosWithDeadline: number;
	nodeadlineTodosResponsibleCount: number[];
	deadlineTodosResponsibleCount: number[];
}

export interface MeetingDraft {
	title?: string;
	place?: string;
	datePick?: Date;
	timePick?: string;
	Tasks?: TaskResource[];
	participants?: MeetingParticipant[];
	meetPauseInput?: number;
	meetBufferInput?: number;
}
